import React from 'react'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'

const tableService = [
  {
    name: "Calendly",
    description: "Appointment booking software",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Canny",
    description: "User feedback software",
    country: "United States",
    location: "	United States"
  },
  {
    name: "FullContact",
    description: "Contact data enrichment",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Google Analytics",
    description: "Insight and analytics",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Hotjar",
    description: "Insight and analytics",
    country: "Malta",
    location: "Ireland"
  },
  {
    name: "Intercom",
    description: "In-app messaging and CRM tool",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Mailchimp",
    description: "Email newsletter tool",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Mailgun",
    description: "Email notification services",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Messagebird",
    description: "SMS notification services",
    country: "United Kingdom",
    location: "	United States"
  },
  {
    name: "Readme.io",
    description: "Documentation and knowledgebase services",
    country: "United Kingdom",
    location: "	United States"
  }, 
  {
    name: "Spreedly",
    description: "Credit card vaulting services",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Statuspage",
    description: "Uptime tracking and service notifications",
    country: "United States",
    location: "	United States"
  }, 
  {
    name: "Stripe",
    description: "Transactions and payments",
    country: "United States",
    location: "	United States"
  }, 
  {
    name: "TaxJar",
    description: "Sales tax automation",
    country: "United States",
    location: "	United States"
  }, 
  {
    name: "Toky",
    description: "	Call center and phone line services",
    country: "United States",
    location: "	United States"
  }
]

const infrastructureTable = [
  {
    name: "Google",
    description: "Cloud hosting provider",
    country: "United States",
    location: "	United States"
  },
  {
    name: "MaxCDN",
    description: "CDN for marketing site",
    country: "United States",
    location: "	United States"
  },
  {
    name: "Microsoft",
    description: "Cloud hosting provider",
    country: "United States",
    location: "	United States"
  },
  {
    name: "WP Engine",
    description: "Marketing site hosting provider",
    country: "United States",
    location: "	United States"
  }
] 

const SubProcessor = () => {
  return(
    <Layout>
      <SEO 
        title="Sub-Processors" 
        description="Get an up-to-date list of all the sub-processors we use to manage your customers data." 
        url="https://www.billsby.com/privacy/sub-processors"
      />

      <div className="default-template ">
        <div className="section-tab">
          <div className="container">
            <ul className="tab-wrapper">
              <li className="tab-item"><Link to="/terms">Terms and conditions</Link></li>
              <li className="tab-item" to="/privacy"><Link>Privacy policy</Link></li>
              <li className="tab-item"><Link to="/privacy/dpa">EU Data Processing Addendum</Link></li>
              <li className="tab-item"><Link to="/privacy/cookie-policy">Cookie policy</Link></li>
              <li className="is-active tab-item"><Link to="/privacy/sub-processors">Sub-processors</Link></li>
              <li className="tab-item"><Link to="/terms/acceptable-use">Acceptable use policy</Link></li>
            </ul>

            <p className="date">EFFECTIVE 1ST AUGUST 2019</p>
            <h1 className="section-heading">Sub-processors</h1>
          </div>
        </div>
        <div className="container default-content">
        <h4>Service specific sub-processors</h4>
        <p>Billsby uses the third parties listed below to provide specific functionality within our services, which result in transfer of personally identifiable customer data.</p>
        <table class="table">
          <thead>
            <th>Name</th>
            <th>Description</th>
            <th>Entity Country</th>
            <th>Location of Data Processing</th>
          </thead>
          {
            tableService.map(( tableItem , i) => (
              <tr key={i}>
                <td data-attr="Name">{tableItem.name}</td>
                <td data-attr="Description">{tableItem.description}</td>
                <td data-attr="Entity Country">{tableItem.country}</td>
                <td data-attr="Location of Data Processing">{tableItem.location}</td>
              </tr>
            ))
          }
        </table>
        <h4>Infrastructure and CDN sub-processors</h4>
        <p>Billsby uses the third parties listed below to host our customer data and to provide specific functionality within our services.</p>
        <table class="table">
          <thead>
            <th>Name</th>
            <th>Description</th>
            <th>Entity Country</th>
            <th>Location of Data Processing</th>
          </thead>
          {
            infrastructureTable.map((tableList, j) => (
              <tr key={j}>
                <td data-attr="Name">{tableList.name}</td>
                <td data-attr="Description">{tableList.description}</td>
                <td data-attr="Entity Country">{tableList.country}</td>
                <td data-attr="Location of Data Processing">{tableList.location}</td>
              </tr>
            ))
          }
        </table>
        </div>  
      </div>
    </Layout>
  )
}
export default SubProcessor
